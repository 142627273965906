import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08372eed"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input" }
const _hoisted_2 = { class: "input-inner" }
const _hoisted_3 = ["type", "name", "id", "value"]
const _hoisted_4 = { class: "unit-label" }
const _hoisted_5 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        type: _ctx.type,
        name: _ctx.name,
        id: _ctx.makeId,
        placeholder: ' ',
        value: _ctx.value,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:value', $event.target.value))),
        onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('input-blur')))
      }, null, 40, _hoisted_3),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.getUnits[_ctx.getUnit]), 1),
      _createElementVNode("label", {
        for: _ctx.makeId,
        class: "label--error"
      }, [
        _renderSlot(_ctx.$slots, "error", {}, undefined, true)
      ], 8, _hoisted_5)
    ])
  ]))
}