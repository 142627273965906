import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1fb19ab7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_input_solid = _resolveComponent("input-solid")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_svg_icon, {
        class: "icon icon--medium icon--margin-right",
        src: `icons/body-measurement.svg`
      }, null, 8, ["src"]),
      _createVNode(_component_input_solid, {
        type: 'text',
        name: 'properties_input',
        class: _normalizeClass({ 'input-solid--error': _ctx.v$.form.input.$error }),
        value: _ctx.v$.form.input.$model,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.form.input.$model) = $event)),
        onKeyup: _ctx.setHeightOnKeyUp,
        onInputBlur: _ctx.handleBlur
      }, {
        error: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.form.$errors, (error) => {
            return (_openBlock(), _createElementBlock("span", {
              class: "error-message",
              key: error.$uid
            }, _toDisplayString(error.$message), 1))
          }), 128))
        ]),
        _: 1
      }, 8, ["class", "value", "onKeyup", "onInputBlur"])
    ])
  ], 32))
}