
import { Options, Vue } from "vue-class-component";
import { v4 as uuidv4 } from "uuid";
import { settingsModule } from "@/store";
import { Units } from "@/types/enum";

@Options({
  name: "InputSolid",
  props: {
    type: {
      type: String,
      default: "text",
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    value: {
      type: [Number, String],
      required: true,
    },
  },
})
export default class InputSolid extends Vue {
  private value!: string;

  get makeId(): string {
    return uuidv4();
  }

  private get getUnit(): number {
    return settingsModule.getUnit || Units.Cm;
  }

  private get getUnits(): Array<string> {
    return settingsModule.getUnits;
  }
}
