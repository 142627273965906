
import { Options, Vue } from "vue-class-component";
import { settingsModule } from "@/store";
import { useVuelidate } from "@vuelidate/core";
import { numeric } from "@vuelidate/validators";
import { InputIntegerInterface } from "@/types";
import InputSolid from "./InputSolid/index.vue";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";

@Options({
  name: "PropertiesInput",
  components: {
    InputSolid,
    SvgIcon,
  },
  validations: {
    form: {
      input: { numeric },
    },
  },
})
export default class PropertiesInput extends Vue {
  private form: InputIntegerInterface = { input: 0 };
  private v$: any = useVuelidate();

  get getHeight(): number {
    return settingsModule.getHeight;
  }

  private handleBlur(): void {
    if (!this.v$.form.input.$model || isNaN(this.v$.form.input.$model)) {
      this.v$.form.input.$model = 0;
    }
  }

  private setHeightOnKeyUp(e: Event): void {
    this.v$.$touch();

    if (!this.v$.$invalid) {
      settingsModule.Height(this.v$.form.input.$model || 0);
    }
  }

  private submit(e: Event): void {
    e.preventDefault();

    this.v$.$touch();

    if (!this.v$.$invalid) {
      settingsModule.Height(this.v$.form.input.$model || 0);
    }
  }

  mounted() {
    if (this.getHeight) {
      this.v$.form.input.$model = this.getHeight;
    }

    this.emitter.on("height-change", (height: number) => {
      this.v$.form.input.$model = height;
    });
  }
}
